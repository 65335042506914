

.slide-in-left {
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 1.5s ease-out, opacity 1.5s ease-out;
}

.slide-in-right {
  opacity: 0;
  transform: translateX(100%);
  transition: transform 1.5s ease-out, opacity 1.5s ease-out;
}

.slide-in-left.active,
.slide-in-right.active {
  opacity: 1;
  transform: translateX(0);
}

/* About.css */

.text-paragraph {
  font-size: 16px; /* Default for small devices */
}

@media (min-width: 480px) {
  .text-paragraph {
    font-size: 18px; /* Adjust font size for medium devices and above */
  }
}
