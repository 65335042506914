.faculty-card-section {
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(21, 23, 26);
  padding: 30px;
  border-radius: 10px;
  flex-wrap: wrap;
}

.faculty-card {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 30px; /* Simplified margin */
  border-radius: 50%;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.faculty-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.faculty-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.faculty-card:hover .faculty-card-image {
  filter: blur(0.5px);
  transform: scale(1.1);
  cursor: pointer;
}

.faculty-card-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #ff4c4c;
  text-align: center;
  padding: 10px;
  opacity: 0; /* Hide text by default */
  transition: opacity 0.3s ease; /* Transition for smooth effect */
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);
}

.faculty-card:hover .faculty-card-info {
  opacity: 1; /* Show text on hover */
}

.faculty-card-info:hover {
  cursor: pointer;
}

.faculty-card-info h3,
.faculty-card-info p {
  transition: font-size 0.3s ease;
}

.faculty-card:hover .faculty-card-info h3,
.faculty-card:hover .faculty-card-info p {
  font-size: 0.9em;
}
@media (max-width: 500px) {
  .faculty-card:hover .faculty-card-info h3,
.faculty-card:hover .faculty-card-info p {
  font-size: 0.7em; 
}
}

.section-heading {
  text-align: center;
  color: #ff4c4c;
  margin-bottom: 1rem;
  font-size: 2.5em;
  font-weight: bold;
  text-transform: uppercase;
  font-family: 'Arial', sans-serif;
  position: relative;
  animation: fadeIn 1s ease-in-out, headingAnimation 1s ease forwards;
}

.section-underline {
  height: 2px;
  background-color: #ff4c4c;
  border: none;
  transition: width 0.3s ease;
  width: 0;
  margin: 0 auto;
  animation: underlineExpand 0.5s ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes headingAnimation {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes underlineExpand {
  0% {
    width: 0;
    left: 50%;
  }
  100% {
    width: 30%;
    left: 35%;
  }
}

/* Media Queries for Responsiveness */

@media (max-width: 1024px) {
  .faculty-card {
    width: 200px; /* Adjust width for tablets */
    height: 200px; /* Adjust height for tablets */
  }
}

@media (max-width: 768px) {
  .faculty-card {
    width: 180px; /* Slightly smaller for small tablets */
    height: 180px;
  }

  .section-heading {
    font-size: 2em; /* Smaller heading for mobile */
  }
}

@media (max-width: 480px) {
  .faculty-card {
    width: 150px; /* Smaller for mobile */
    height: 150px;
    margin: 15px; /* Reduce margin for mobile */
  }

  .faculty-card-info h3 {
    font-size: 1em; /* Smaller font for names */
  }

  .faculty-card-info p {
    font-size: 0.8em; /* Smaller font for descriptions */
  }

  .section-heading {
    font-size: 1.5em; /* Smaller heading for very small devices */
  }
}
