.homcard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 50px 0;
}

.homcard {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  width: 430px;
  height: 350px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.homimage {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: contain center;
  transition: transform 0.3s ease;
}

.homcard:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 0 3px rgb(166, 22, 22);
}

.homcard:hover .homimage {
  transform: scale(1.05);
  cursor: pointer;
}

@media (max-width: 1024px) {
  .homcard-container {
    gap: 20px;
  }

  .homcard {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 768px) {
  .homcard-container {
    gap: 15px;
  }

  .homcard {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 480px) {
  .homcard-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .homcard {
    width: 100%;
    max-width: 300px;
    height: auto;
    aspect-ratio: 1;
  }
}
