.card-alumni{
  width: 20vw;
  height: 50vh;
  display: flex;
  flex-direction: column;
  border-radius: 2vw;
  overflow: hidden;
  row-gap: 30vh;
  align-self: normal;
  z-index: 2;
  filter: brightness(0.8);

}
.card-alumni img{
position: absolute;
top: 0;
width: 100%;
height: 100%;
}
.card-alumni:hover{
  transform: scale(1.05);
  transition: transform 0.5s ease-in-out;
  filter: brightness(1);
  animation: rot 1s linear;
  box-shadow: 5px 5px 20px rgb(255, 0, 0),
  -5px -5px 20px black;
}
@keyframes rot{
  0%, 100%{
    box-shadow: 5px 5px 20px rgb(165, 4, 4),
                -5px -5px 20px black;
  }
  20%{
    box-shadow: -5px 5px 20px rgb(165, 4, 4),
                  5px -5px 20px black;
  }
  50%{
    box-shadow: -5px -5px 20px rgb(165, 4, 4),
                  5px 5px 20px black;
  }
  80%{
    box-shadow: 5px -5px 20px rgb(165, 4, 4),
                  -5px 5px 20px black;
  }
}
.bottom-Alumni{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  /* column-gap: 7vw; */
  height: 25%;
  position: absolute;
  bottom: 0;
}
.items-Alumni{
  display: flex;
  flex-direction: column;
  row-gap: 0.2vh;
}
.card-alumni h2{
  z-index: 2;
  display: flex;
  color: white;
  font-family: "Poppins", sans-serif;
  margin-left: 8vw;
  margin-top: 26vh;
  text-wrap: wrap;
  transform: scale(0);
  font-size: 1.3em;

}
.card-alumni:hover h2{
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

.icons-Alumni{
  display: flex;
  justify-content: space-evenly;
  font-size: 2em;
  color: black;
  background:transparent;
  z-index: 2;
  column-gap: 4vw;
  margin-bottom: 3vh;
  margin-left: 2vw;
}
@media screen and (max-width: 945px){
  .card-alumni h2{
    margin-left: 12vw;
    margin-top: 18vh;
  }
  .icons-Alumni{
    column-gap: 5vw;
  }
}
.delay1{
  transform: scale(0);
  transition: transform 0.5s ease;
  transition-delay: 0.3s;
  
}
.delay1:hover{
  cursor: pointer;
}
.delay2:hover{
  cursor: pointer;
}
.delay3:hover{
  cursor: pointer;
}
.delay2{
  transform: scale(0);
  transition: transform 0.5s ease;
  transition-delay: 0.2s;
}
.delay3{
  transform: scale(0);
  transition: transform 0.5s ease;
  transition-delay: 0.1s;
}

.card-alumni:hover .delay1{
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
  transition-delay: 0.3s;
}
.card-alumni:hover .delay2{
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
  transition-delay: 0.2s;

}
.card-alumni:hover .delay3{
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

@keyframes animates{
  0%{
    height: 0;
  }
  100%{
    height: 20%;
  }
}
#alumni-button{
  height: 30px;
  background-color: #DA4D1D;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  outline:none;
  transition: all 0.5s ease;
  width: 30px;
  display: flex;
  opacity: 70%;
  /* mix-blend-mode: hard-light; */

}

.card-alumni:hover button{
  transform: scale(20.5);
  mix-blend-mode: hard-light;
}

@media screen and (max-width: 1199px){

  .card-alumni h2{
    /* margin-left: 15vw; */
    margin-top: 12vh;
    font-size: 0.8em;
    }
  .card-alumni{
    height: 35vw;
    width: 35vw;
  }
  .icons-Alumni{
    font-size: 1.5rem;
  }

  .card-alumni:hover button{
    transform: scale(16.5);
  }
  
}
@media screen and (max-width: 1199px) and (max-height: 1300px){
  .card-alumni{
    height: 30vw;
    width: 25vw;
  }
}
@media screen and (min-width: 1200px) and (max-width:1600px){
  .card-alumni{
    width: 20vw;
    height: 20vw;
  }
  .card-alumni h2{
    margin-left: 10vw;
    margin-top: 13vh;
  }
}
@media screen and (max-width: 700px){
  .card-alumni{
    width: 40vw;
  }
  .card-alumni:hover button{
    transform: scale(17);
  }

}
@media screen and (max-height: 1024px) and (max-width: 700px){
  .card-alumni {
    height: 25vh;
  }

}
@media screen and (max-width: 600px){
  .card-alumni{
    width: 60vw;
    height: 30vh;
  }
  .card-alumni:hover button{
    transform: scale(16);
  }
@media screen and (max-width: 600px) and (max-height: 800px){
  .card-alumni{
    height: 50vh;
  }
  }

}
@media screen and (min-width: 2000px){
  .icons-Alumni{
   font-size: 2.5rem;
 
  }
  .card-alumni h2{
     margin-left: 9vw;
     margin-top: 31vh;
     font-size: 2.3rem;
  }
 }

@media screen and (max-width: 400px){
  .card-alumni{
    width: 60vw;
    height: 35vh;
  }
  .card-alumni:hover button{
    transform: scale(14);
  }

}
@media screen and (max-width: 400px) and (min-height: 800px){
  .card-alumni{
    width: 65vw;
    height: 30vh;
  }

}