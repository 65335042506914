.speakers-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.organisers-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns for larger screens */
  gap: 20px;
  max-width: 1200px;
  width: 100%;
}

.ted-card {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.ted-card:hover {
  transform: rotate(3deg);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.ted-imgBx {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Maintain a square aspect ratio */
  background-color: #333;
  overflow: hidden;
}

.ted-imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire area */
  object-position: center; /* Center the image */
  transition: transform 0.5s ease-in-out;
}

.ted-card:hover .ted-imgBx img {
  transform: scale(1.1);
}

.ted-details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  transition: background 0.3s ease;
}

.ted-details h2 {
  font-size: 1.5rem;
  margin: 0;
  color: #fff;
}

.ted-details span {
  font-size: 1rem;
  color: #e74c3c; 
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .organisers-container {
    grid-template-columns: repeat(2, 1fr); /* 2 columns for medium screens */
  }
}

@media (max-width: 768px) {
  .organisers-container {
    grid-template-columns: repeat(2, 1fr); 
    gap: 16px; 
  }
}

@media (max-width: 480px) {
  .organisers-container {
    grid-template-columns: repeat(1, 1fr); 
  }
}
