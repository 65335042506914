.mlw {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: rgb(21, 23, 26);
}

.Mlw-heading {
    color: red;
    font-size: 3rem;
    margin: 50px 0;
    text-align: center;
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.Mlw-heading.in-view {
    opacity: 1;
    transform: translateY(0);
}

.Mlw-paragraph {
    color: rgb(144, 144, 144);
    font-size: 1.2rem;
    margin-bottom: 30px;
    text-align: center;
    max-width: 800px;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.Mlw-paragraph.in-view {
    opacity: 1;
    transform: translateY(0);
}

.Mlw-gallery {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.Mlw-gallery.in-view {
    opacity: 1;
    transform: translateY(0);
}

.Mlw-gallery-item {
    width: 600px;
    height: 350px;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease;
}

.Mlw-gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: filter 0.3s ease;
    filter: brightness(70%);
}

.Mlw-gallery-item:hover img {
    filter: brightness(100%);
    transform: scale(1.05);
}

.animated-line {
    width: 80%;
    height: 2px;
    margin: 40px auto;
    background: linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 1) 50%, rgba(255, 0, 0, 0) 100%);
    background-size: 200% 100%;
    animation: slide 2s ease-in-out infinite;
}

@keyframes slide {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 0%;
    }
}

/* For tablets and medium devices */
@media (max-width: 1024px) {
    .Mlw-heading {
        font-size: 2.8rem;
    }

    .Mlw-paragraph {
        font-size: 1.1rem;
    }

    .Mlw-gallery-item {
        width: 400px;
        height: 250px;
    }
}

/* For mobile devices (tablets and smaller) */
@media (max-width: 768px) {
    .Mlw-heading {
        font-size: 2.4rem;
    }

    .Mlw-paragraph {
        font-size: 1.2rem;
    }

    .Mlw-gallery-item {
        width: 300px;
        height: 200px;
    }
}

/* For smaller screens (phones in portrait) */
@media (max-width: 480px) {
    .Mlw-heading {
        font-size: 2rem;
    }

    .Mlw-paragraph {
        font-size: 1.1rem;
    }

    .Mlw-gallery-item {
        width: 240px;
        height: 160px;
    }

    .animated-line {
        width: 90%;
    }
}
