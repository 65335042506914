
.ted-image {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-height: 300px;
  object-fit: cover;
  object-position: top;
}

.ted-image:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  border: 2px solid rgba(255, 0, 0, 0.5);
}

.ted-close-button {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  border: 2px solid white;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.ted-close-button:hover {
  background-color: darkred;
}

@media (max-width: 768px) {
  .ted-close-button {
    width: 35px;
    height: 35px;
    font-size: 20px;
    top: 5px;
    right: 5px;
  }
}

@media (max-width: 480px) {
  .ted-close-button {
    width: 30px;
    height: 30px;
    font-size: 18px;
    top: 2px;
    right: 2px;
  }
}



/* .gallery{
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
  margin-top: 140px;
}

.gallery .pics{
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;

  animation: fadeIn 1s ease-in-out; 
}

.gallery .pics:hover{
  filter: opacity(0.6);
  transform: scale(1.05); 
  transition: transform 0.3s ease, filter 0.3s ease;
}

@media (max-width: 991px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}



@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
    margin-top: 50px;
  }
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
  animation: modalEntrance 0.5s ease-in-out; 
}

.model.open svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  cursor: pointer;
}

.hdngng{
  position: absolute;
  top: 49.5%;
  left: 40%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 40px;
  text-align: center;
  color: rgba(202, 208, 250, 0.4);
}

@keyframes modalEntrance {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} */
