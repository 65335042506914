.aar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: rgb(21, 23, 26);
}

.aar-heading {
    color: red;
    font-size: 3rem;
    margin: 50px 0;
    text-align: center;
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.aar-heading.in-view {
    opacity: 1;
    transform: translateY(0);
}

.aar-paragraph {
    color: rgb(144, 144, 144);
    font-size: 1.2rem;
    margin-bottom: 30px;
    text-align: center;
    max-width: 800px;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.aar-paragraph.in-view {
    opacity: 1;
    transform: translateY(0);
}

.aar-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
    width: 100%;
    max-width: 1200px;
}

.aar-cards.in-view {
    opacity: 1;
    transform: translateY(0);
}

.aar-card {
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.1);
    /* Glassmorphism effect */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    cursor: pointer;
    /* Pointer cursor for interactivity */
}

.aar-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
}

.aar-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: transform 0.5s ease, filter 0.5s ease;
}

.aar-card:hover .aar-card-image {
    transform: scale(1.1);
    filter: brightness(0.9);
    /* Darken image on hover */
}

.aar-animated-line {
    width: 80%;
    height: 2px;
    margin: 40px auto;
    background: linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 1) 50%, rgba(255, 0, 0, 0) 100%);
    background-size: 200% 100%;
    animation: slide 2s ease-in-out infinite;
}

@keyframes slide {
    0% {
        background-position: 0% 0%;
    }

    100% {
        background-position: 100% 0%;
    }
}

@media (max-width: 768px) {
    .aar-heading {
        font-size: 2.6rem;
    }

    .aar-paragraph {
        font-size: 1.3rem;
    }

    .aar-cards {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 375px) {
    .aar-heading {
        font-size: 2.4rem;
    }

    .aar-paragraph {
        font-size: 1.2rem;
    }

    .aar-cards {
        grid-template-columns: repeat(1, 1fr);
    }
}