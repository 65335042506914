
html,
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.footer {
    background: black;
    color: #ffffff;
    padding: 25px 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
}

.footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.footer-col {
    flex: 1;
    padding: 20px;
    max-width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.footer-logo {
    width: 120px;
    margin-bottom: 20px;
}

.footer-head {
    color: rgb(202, 52, 53);
    font-size: 1.5em;
    margin-bottom: 20px;
    position: relative;
}

.footer-head::after {
    content: '';
    width: 50px;
    height: 2px;
    background: rgb(202, 52, 53);
    position: absolute;
    bottom: -10px;
    left: 0;
}

.footer-social {
    display: flex;
    gap: 15px;
}

.footer-social-link {
    color: #ffffff;
    font-size: 24px;
    transition: color 0.3s ease;
}

.footer-social-link:hover {
    color: rgb(202, 52, 53);
}

.footer-contact {
    margin-bottom: 10px;
    font-size: 1.2em;
    display: flex;
    align-items: center;
}

.flex-item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.footer-contact span {
    margin-left: 5px;
}

.footer-copy {
    background: rgb(15, 16, 18);
    color: #ffffff;
    text-align: center;
    padding: 20px 0;
}

.footer-copy-container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.footer-copy-link {
    color: rgb(202, 52, 53);
    text-decoration: none;
}

.footer-copy-link:hover {
    text-decoration: underline;
}

.anchor {
    color: white;
    text-decoration: none;
    font-family: 'Times New Roman', Times, serif;

}

.anchor:hover {
    color: rgb(202, 52, 53);
}

@media (max-width: 768px) {
    .footer-col {
        max-width: 48%;
        padding: 10px;
    }

    .footer-row {
        justify-content: space-around;
    }
}

@media (max-width: 480px) {
    .footer-col {
        max-width: 100%;
        padding: 10px;
    }

    .footer-social-link {
        font-size: 20px;
    }

    .footer-head {
        font-size: 1.2em;
    }

    .footer-contact {
        font-size: 1em;
    }
}
