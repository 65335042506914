
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@fortawesome/fontawesome-free/css/all.min.css";


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #0000;
}
@layer utilities {
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  } 
}
