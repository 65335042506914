
.wave-container-alumni {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(21,23,26);
  overflow: hidden;
  z-index: 1;
}

.waves-alumni {
  position: absolute;
  width: 100%;
  height: 20vh;
  min-height: 100px;
  left: 0;
}

@media screen and (max-width: 1180px){

  .waves-alumni{
    height: 12vh;
  }
}
.parallax-alumni > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax-alumni > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax-alumni > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax-alumni > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax-alumni > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}