/* Default styling for larger screens */
.ted-organisers-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* 4 cards per row */
  gap: 40px;
  /* Gap between cards */
  padding: 0 60px;
  /* Increased left and right padding */
}

.ted-image-card {
  width: 100%;
  /* Full width for each card in the grid */
  aspect-ratio: 1 / 1;
  /* Ensures square shape */
  position: relative;
  transition: transform 0.4s ease-in;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.ted-image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.6s ease-in;
  border-radius: 10px;
}

.ted-image-card:hover img {
  transform: scale(1.1);
  cursor: pointer;
}

.ted-image-card::after {
  content: attr(data-name);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  /* Height of the name overlay */
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(100%);
  transition: transform 0.4s ease-in;
}

.ted-image-card:hover::after {
  transform: translateY(0);
}

/* Media queries for responsive design */
@media (max-width: 1024px) {
  .ted-organisers-container {
    grid-template-columns: repeat(3, 1fr);
    /* 3 cards per row on medium screens */
    padding: 0 40px;
    /* Reduced padding for medium screens */
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .ted-organisers-container {
    grid-template-columns: repeat(2, 1fr);
    /* 2 cards per row on smaller screens */
    padding: 0 20px;
    /* Reduced padding for smaller screens */
    gap: 24px;
  }

  .ted-image-card {
    height: auto;
  }
}

@media (max-width: 480px) {
  .ted-organisers-container {
    grid-template-columns: 1fr;
    /* 1 card per row on mobile screens */
    padding: 0 10px;
    /* Minimal padding for mobile */
    gap: 20px;
  }

  .ted-image-card {
    height: auto;
  }
}