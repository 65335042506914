@import url("https://fonts.googleapis.com/css2?family=poppin:wght@400;500&display=swap");

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: block;
  justify-content: center;
  align-items: center;
  background-color: #15171A;
  overflow-x: hidden;
}


.arhn-c-img {
  height: 100%;
  width: 100%;
  object-fit: center;
}


.about-arhn-heading {
  color: #ff0000;
  font-size: 30px;
  line-height: 62px;

}

p {
  line-height: 27px;
  font-family: 'Poppins', sans-serif;
  color: #9ca9b3;
  letter-spacing: -0.1px;

}

/* Event arhn-card */



.arhn-card {
  position: relative;
  /* width: 400px; */
  /* height: 450px; */

  border-radius: 10px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.arhn-card img {
  width: 100%;
  object-fit: center;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.arhn-card:hover {
  transform: rotate(-5deg) scale(1.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.arhn-card-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 40px;
  box-sizing: border-box;
  background-color: #15171A;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.arhn-card:hover .arhn-card-content {
  transform: translate(-50%, -50%) rotate(0deg);
  opacity: 1;
}


.arhn-card-description {
  margin: 20px 0 0;
  
  color: #777;
  line-height: 1.4;
}


/* AAROHAN GALLERY */

.arhn-slider {
  height: 300px;
  
  margin: 50px auto;
  position: relative;
  width: 90%;
  display: flex;
  place-items: center;
  overflow: hidden;

}

.arhn-slide-track {
  display: flex;

  animation: scroll 40s linear infinite;
}

.arhn-slider:hover .arhn-slide-track {
  animation-play-state: paused;
}

@keyframes scroll {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.arhn-single-slide {
  border-radius: 5%;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
  
}

.arhn-single-slide img{
  width: 100%;
  height: 150%;
  transition: transform 1s;
  border-radius: 5%;
}

.arhn-single-slide:hover img:hover {
  transform: translateZ(10px);
  border-radius: 5%;
}

.arhn-slider::before,
.arhn-slider::after {
  background: linear-gradient(to right, #15171A 0%, rgba(255, 255, 255, 0) 100%);
  content: '';
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}

.arhn-slider::before {
  left: 0;
  top: 0;
}

.arhn-slider::after {
  right: 0;
  top: 0;
  transform: rotate(180deg);
}
