
.arhn-org-card {
    position: relative;
    width: 250px;
    height: 250px;
    background: #15171A;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 1s ease-in-out;
  
  }
  
  @media (min-width: 1025px) {
    .arhn-org-card {
      width: 300px;
      height: 300px;
    }
  }
  
  .background {
    position: absolute;
    inset: 0;
  
  }
  
  
  
  
  .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
  }
  
  .icon .svg {
  
    fill: #ff0000;
    width: 100%;
    transition: all 0.5s ease-in-out;
  }
  
  .box {
    position: absolute;
    padding: 10px;
    text-align: right;
    background: rgba(255, 255, 255, 0.389);
    border-top: 2px solid rgb(255, 255, 255);
    border-right: 1px solid white;
    border-radius: 10% 13% 42% 0%/10% 12% 75% 0%;
    box-shadow: rgba(100, 100, 111, 0.364) -7px 7px 29px 0px;
    transform-origin: bottom left;
    transition: all 1s ease-in-out;
  }
  
  .box::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  
  .box:hover .svg {
    fill: #bec4da;
    width: 40px;
  }
  
  .box1 {
    width: 70%;
    height: 70%;
    bottom: -70%;
    left: -70%;
  }
  
  
  
  .box1:hover::before {
    opacity: 1;
  }
  
  
  .box2 {
    width: 50%;
    height: 50%;
    bottom: -50%;
    left: -50%;
    transition-delay: 0.1s;
  }
  
  
  .box2:hover::before {
    opacity: 1;
  }
  
  
  
  .box3 {
    width: 30%;
    height: 30%;
    bottom: -30%;
    left: -30%;
    transition-delay: 0.3s;
  }
  
  
  
  .box3:hover::before {
    opacity: 1;
  }
  
  
  
  .box4 {
    width: 10%;
    height: 10%;
    bottom: -10%;
    left: -10%;
    transition-delay: 0.5s;
  }
  
  .arhn-org-card:hover {
    transform: scale(1.1);
  }
  
  .arhn-org-card:hover .box {
    bottom: -1px;
    left: -1px;
  }
  
  .arhn-org-img {
    transition-duration: 1s;
  }
  
  .arhn-org-card:hover .arhn-org-img {
    opacity: 0;
  }
  
