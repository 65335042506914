.sdv {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: rgb(21, 23, 26);
}

.Sdv-heading {
    color: red;
    font-size: 3rem;
    margin: 50px 0;
    text-align: center;
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.Sdv-heading.in-view {
    opacity: 1;
    transform: translateY(0);
}

.Sdv-paragraph {
    color: rgb(144, 144, 144);
    font-size: 1.2rem;
    margin-bottom: 30px;
    text-align: center;
    max-width: 800px;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.Sdv-paragraph.in-view {
    opacity: 1;
    transform: translateY(0);
}

.Sdv-gallery {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.Sdv-gallery.in-view {
    opacity: 1;
    transform: translateY(0);
}

.Sdv-gallery-item {
    width: 300px;
    height: 350px;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease;
}

.Sdv-gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: filter 0.3s ease;
    filter: brightness(70%);
}

.Sdv-gallery-item:hover img {
    filter: brightness(100%);
    transform: scale(1.05);
}

.animated-line {
    width: 80%;
    height: 2px;
    margin: 40px auto;
    background: linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 1) 50%, rgba(255, 0, 0, 0) 100%);
    background-size: 200% 100%;
    animation: slide 2s ease-in-out infinite;
}

@keyframes slide {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 0%;
    }
}


@media (max-width: 768px) {
    .Sdv-heading {
        font-size: 2.6rem;
    }

    .Sdv-paragraph {
        font-size: 1.3rem;
    }

    .Sdv-gallery-item {
        width: 250px;
        height: 300px;
    }
}

@media (max-width: 376px) { 
    .Sdv-heading {
        font-size: 2.6rem;
    }

    .Sdv-paragraph {
        font-size: 1.3rem;
    }

    .Sdv-gallery-item {
        width: 250px;
        height: 300px;
    }
}
