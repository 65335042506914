.tedxsec {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: rgb(21, 23, 26);
  }
  
  .TedxSec-heading {
    color: red;
    font-size: 3rem;
    margin: 50px 0;
    text-align: center;
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .TedxSec-heading.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  .TedxSec-paragraph {
    color: rgb(144, 144, 144);
    font-size: 1.2rem;
    margin-bottom: 30px;
    text-align: center;
    max-width: 800px;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .TedxSec-paragraph.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  .TedxSec-cards {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .TedxSec-cards.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  .TedxSec-card {
    width: 250px;
    height: 350px;
    perspective: 1000px;
  }
  
  .TedxSec-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  
  .TedxSec-card:hover .TedxSec-card-inner {
    transform: rotateY(180deg);
  }
  
  .TedxSec-card-front,
  .TedxSec-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .TedxSec-card-front {
    background-color: #333;
  }
  
  .TedxSec-card-back {
    background-color: rgb(55, 55, 55);
    color: rgb(207, 207, 207);
    transform: rotateY(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
  
  .TedxSec-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .animated-line {
    width: 80%;
    height: 2px;
    margin: 40px auto;
    background: linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 1) 50%, rgba(255, 0, 0, 0) 100%);
    background-size: 200% 100%;
    animation: slide 2s ease-in-out infinite;
  }
  
  @keyframes slide {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 0%;
    }
  }
  
  @media (max-width: 1024px) {
    .TedxSec-heading {
      font-size: 2.6rem;
    }
  
    .TedxSec-paragraph {
      font-size: 1.3rem;
    }
  
    .TedxSec-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .TedxSec-card {
      width: 300px;
      height: 400px;
    }
  }
  
  @media (max-width: 768px) {
    .TedxSec-heading {
      font-size: 2.6rem;
    }
  
    .TedxSec-paragraph {
      font-size: 1.3rem;
    }
  
    .TedxSec-cards {
      gap: 15px;
    }
  
    .TedxSec-card {
      width: 300px;
      height: 400px;
    }
  }

@media (max-width: 1024px) and (min-width: 768px) {
  .TedxSec-heading {
    font-size: 2.4rem; 
  }

  .TedxSec-paragraph {
    font-size: 1.2rem; 
  }

  .TedxSec-cards {
    gap: 20px; 
    flex-direction: row; 
  }

  .TedxSec-card {
    width: 250px; 
    height: 350px; 
  }

  .TedxSec-card img {
    width: 100%;
    height: 100%; 
    object-fit: cover; 
  }
}
