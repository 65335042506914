.dnd {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: rgb(21, 23, 26);
  }
  
  .Dnd-heading {
    color: rgb(255, 0, 0);
    font-size: 2.5rem;
    margin: 50px 0;
    text-align: left;
    opacity: 0;
    transform: translateY(-50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .Dnd-heading.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  .Dnd-paragraph {
    color: rgb(144, 144, 144);
    font-size: 1.2rem;
    margin-bottom: 30px;
    text-align: center;
    max-width: 800px;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .Dnd-paragraph.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  .Dnd-cards {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .Dnd-cards.in-view {
    opacity: 1;
    transform: translateY(0);
  }
  
  .Dnd-card {
    width: 250px;
    height: 350px;
    perspective: 1000px;
    transition: transform 0.3s ease;
  }
  
  .Dnd-card:hover {
    transform: translateY(-10px) scale(1.05);
  }
  
  .Dnd-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .animated-line {
    width: 80%;
    height: 2px;
    margin: 40px auto;
    background: linear-gradient(90deg, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 1) 50%, rgba(255, 0, 0, 0) 100%);
    background-size: 200% 100%;
    animation: slide 2s ease-in-out infinite;
  }
  
  @keyframes slide {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 0%;
    }
  }

  @media (max-width: 785px) {
    .Dnd-heading {
      font-size: 2.6rem;
      margin: 30px 0;
    }
  
    .Dnd-paragraph {
      font-size: 1.3rem; 
      margin-bottom: 20px;
      padding: 0 15px;
    }
  
    .Dnd-cards {
      gap: 20px;
    }
  
    .Dnd-card {
      width: 300px; 
      height: 400px;
    }
  
    .animated-line {
      width: 90%;
      margin: 50px auto;
    }
  }
  
  @media (max-width: 480px) {
    .Dnd-heading {
      font-size: 2.6rem; 
      margin: 50px 0;
    }
  
    .Dnd-paragraph {
      font-size: 1.3rem; 
      margin-bottom: 45px;
    }
  
    .Dnd-cards {
      gap: 15px;
    }
  
    .Dnd-card {
      width: 300px; 
      height: 400px; 
    }
  
    .animated-line {
      width: 90%;
      margin: 20px auto;
    }
  }