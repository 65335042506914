/* Main Wrapper for Center Alignment */
.homyt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  overflow: hidden;
  padding: 0 10px;
}

/* Title Styling */
.homyt-sae h1 {
  font-family: 'Poppins', sans-serif;
  color: rgb(235, 21, 21);
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 20px;
  text-shadow:
    1px 1px 0 rgb(0, 0, 0),
    2px 2px 0 rgb(0, 0, 0),
    2px 2px 0 rgb(148, 31, 31),
    4px 4px 0 rgb(0, 0, 0),
    5px 5px 0 rgb(0, 0, 0);
  position: relative;
}

.homyt-sae h1::before {
  content: ''; /* Ensures pseudo-element displays */
  position: absolute;
  top: 5px;
  left: 5px;
  color: #000;
  z-index: -1;
  text-shadow: none;
}

/* Scrolling Video Thumbnails */
.homyt > div {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  animation: scroll 40s linear infinite;
  align-items: center;
}

.homyt:hover > div {
  animation-play-state: paused;
}

.homyt iframe {
  margin: 0 5px;
  width: 280px;
  height: 158px;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Main Video Section */
.homvid {
  width: 100%;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.homvideo-container1 {
  position: relative;
  padding-top: 40%; /* Aspect Ratio */
  max-height: 500px;
}

.homreact-player,
.homthumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Slider Section */
.homslider-container {
  width: 100%;
  overflow: hidden;
  margin-top: 20px; /* Adds spacing between sections */
}

.homslider {
  display: flex;
  justify-content: center;
  align-items: center;
}

.homslide-track {
  display: flex;
  animation: scroll 20s linear infinite;
}

.homslide {
  min-width: 240px;
  margin-right: 20px; /* Increased margin for more space */
  box-sizing: border-box;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Responsive Adjustments */
@media (max-width: 480px) {
  .homvideo-container1 {
    padding-top: 60%; /* Increase height for mobile devices */
  }

  .homslide {
    width: 40vw;
    margin-right: 10px; /* Smaller margin for compact layout */
  }
}

@media (min-width: 768px) {
  .homvid {
    max-height: 500px;
  }
}

.homthumbnail {
  width: 100%;
  height: auto;
  cursor: pointer;
  object-fit: cover; /* Ensures consistent display */
}
